import { Sidebar } from 'src/app/types/sidebar';
export const sidebar: Sidebar[] = [
    // Member
    {
        id: 'user-eligibility',
        title: 'ตรวจสอบความคุ้มครอง',
        icon: 'npicon-user-protection',
        type: 'item',
        role: 'member',
        url: '/policy-eligibility',
        permits: 1
    },
    {
        id: 'user-reserve-pending',
        title: 'รอการจองสิทธิ์',
        icon: 'npicon-reserve-pending',
        type: 'item',
        role: 'member',
        url: '/reserve-pending',
        permits: 3,
        // disable : true

    },
    {
        id: 'user-reserve-status',
        title: 'สถานะ<br>การจองสิทธิ์',
        icon: 'npicon-user-status',
        type: 'collapsable',
        role: 'member',
        url: '/status',
        permits: 2,
        children: [
            {
                id: 'opd',
                title: 'ผู้ป่วยนอก (OPD)',
                type: 'item',
                role: 'member',
                url: '/status/opd'
            },
            {
                id: 'ipd',
                title: 'ผู้ป่วยใน <br> (IPD/Day case)',
                type: 'item',
                role: 'member',
                url: '/status/ipd'
            }
        ]
    },
    {
        id: 'user-payment',
        title: 'การวางบิล<br>และชำระเงิน',
        icon: 'npicon-user-payment',
        type: 'collapsable',
        role: 'member',
        url: '/payment',
        permits: 4,
        children: [
            {
                id: 'claim-submission',
                title: 'รายการ<br>รอการวางบิล',
                type: 'item',
                role: 'member',
                url: '/payment/claim-submission'
            },
            {
                id: 'claim-enquiry',
                title: 'ตรวจสอบ<br>สถานะการวางบิล',
                type: 'item',
                role: 'member',
                url: '/payment/claim-enquiry'
            }
        ]
    },
    {
        id: 'user-report',
        title: 'รายงาน',
        icon: 'npicon-user-report',
        type: 'collapsable',
        role: 'member',
        url: '/report',
        hidden: true
    },
    {
        id: 'user-claim-guide',
        title: 'จดหมายแจ้ง / คำถามที่พบบ่อย',
        icon: 'npicon-user-guide',
        type: 'item',
        role: 'member',
        url: '/guide'
    },
    {
        id: 'user-policy-insurance',
        title: 'กรมธรรม์ประกันภัย',
        icon: 'npicon-user-guide',
        type: 'collapsable',
        role: 'member',
        url: '/policy-insurance',
        permits: 6,
        children: [
            {
                id: 'policy-insurance-travel',
                title: 'Travel',
                type: 'item',
                role: 'member',
                url: '/policy-insurance/travel'
            },
            {
                id: 'policy-insurance-non-travel',
                title: 'Non-Travel',
                type: 'item',
                role: 'member',
                url: '/policy-insurance/non-travel'
            }
        ]
    },
    // admin
    {
        id: 'admin-request-pending',
        title: 'รายการรอการจองสิทธิ์ OPD',
        icon: 'npicon-reserve-pending',
        type: 'item',
        role: 'admin',
        url: '/admin/reserve-pending'
    },
    {
        id: 'admin-hospital',
        title: 'รายชื่อโรงพยาบาลคู่สัญญา',
        icon: 'npicon-admin-hospital',
        type: 'item',
        role: 'admin',
        url: '/admin/hospital'
    },
    {
        id: 'admin-department',
        title: 'รายชื่อแผนก',
        icon: 'npicon-admin-department',
        type: 'item',
        role: 'admin',
        url: '/admin/department'
    },
    {
        id: 'admin-benefit-display-setup',
        title: 'Benefit Display Setup',
        icon: 'npicon-cog',
        type: 'item',
        role: 'admin',
        url: '/admin/benefit-display-setup',
    },
    {
        id: 'admin-special',
        title: 'GroupDisease Setup',
        icon: 'npicon-cog',
        type: 'item',
        role: 'admin',
        url: '/admin/special'
    },
    {
        id: 'admin-claim-guide',
        title: 'จดหมายแจ้ง / คำถามที่พบบ่อย',
        icon: 'npicon-user-guide',
        type: 'item',
        role: 'admin',
        url: '/admin/guide'
    },
    {
        id: 'admin-banner',
        title: 'จัดการ Banner',
        icon: 'npicon-admin-banner',
        type: 'item',
        role: 'admin',
        url: '/admin/banner'
    }
];