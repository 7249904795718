import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils, AuthenticationParameters } from 'msal';

import { ChubbAuthenticationService } from '../_services';
import { MSAL_SCOPES } from '../_config/app-config';
import { Adb2cState } from '@chubb-auth/types';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  model: any = {};
  errorMessage?: string;
  correlationId = CryptoUtils.createNewGuid();
  loggedIn = false;

  constructor(private router: Router, private ngZone: NgZone, private readonly _authService: ChubbAuthenticationService,) {
    ////debugger;
  }

  ngOnInit() {
    ////debugger;
  }
}
