<!-- <div class="reset-password-header">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <div class="col d-flex justify-content-end">
                <div class="language-switcher-container" (clickOutside)="closeLangs()">
                    <button class="language-switcher text-uppercase"
                        (click)="openLangs()">{{ translate.currentLang }}<span class="caret-down"><i
                                class="npicon-down"></i></span></button>
                    <ul class="language-lists" [ngClass]="{'opened': isLangOpened}">
                        <li *ngFor="let lang of translate.getLangs()">
                            <a href="#" (click)="changeLanguage($event, lang)">{{ lang }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div id="reset-password" class="d-flex align-items-center">

    <div id="reset-password-wrapper" class="container">

        <!-- logo -->
        <div class="cigna-logo lg mx-auto mb-4"></div>
        <!-- / logo -->

        <!-- Reset password form -->
        <div *ngIf="!isResetCompleted">
            <h2 class="text-center mb-4">Reset your password</h2>

            <div class="row justify-content-center">
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit($event)" class="col-12 col-md-8 col-lg-5 p-0"
                    id="resetPasswordForm">
                    <div class="row justify-content-center">
                        <div class="col-8">

                            <div class="form-group my-0" *ngIf="user">
                                <label class="m-0">Username: <span class="text-primary">{{user.userID}}</span></label>
                            </div>

                            <div class="form-group my-0">
                                <div class="d-flex align-items-center {{ resetPasswordForm.value.usercode ? 'is-focused' : '' }}" [ngClass]="displayFieldCss('password')">
                                    <div class="input-container">
                                        <input type="text" id="password" name="password" formControlName="usercode" autocomplete="off">
                                        <label for="password">New password (between 7-10 characters)</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group my-0">
                                <div class="d-flex align-items-center {{ resetPasswordForm.value.pConfirm ? 'is-focused' : '' }}" [ngClass]="displayFieldCss('confirmPassword')">
                                    <div class="input-container">
                                        <input type="text" id="confirmPassword" name="confirmPassword" formControlName="pConfirm" autocomplete="off">
                                        <label for="confirmPassword">Confirm new password</label>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4" *ngIf="isPasswordLengthValid()"><p class="text-red m-0">Contain between 7-10 characters</p></div>

                            <div class="mt-4" *ngIf="resetPasswordForm.get('usercode').value != resetPasswordForm.get('pConfirm').value && resetPasswordForm.get('pConfirm').value != null">
                                <p class="text-red m-0">Password doesn't match</p>
                            </div>

                            <div class="mt-4" *ngIf="resetPasswordForm.get('usercode').errors !== null && resetPasswordForm.get('usercode').errors.pattern">
                                <p class="text-red m-0">Does not contain "-", "_", " ", "$", "&"</p>
                            </div>

                        </div>
                    </div>

                    <div class="form-row justify-content-center">
                        <div class="my-5 col-7">
                            <!-- <button class="cn-btn green full" [ngClass]="{ 'disabled': isButtonDisabled || !(resetPasswordForm.valid) }" [disabled]="isButtonDisabled || !(resetPasswordForm.valid)">Reset Password</button> -->
                            <button class="cn-btn full green" *ngIf="!isButtonDisabled && (resetPasswordForm.valid)">Reset Password</button>
                            <button class="cn-btn full gray" *ngIf="isButtonDisabled || !(resetPasswordForm.valid)" [disabled]="true">Reset Password</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- / Reset password form -->

        <!-- Reset completed -->
        <div *ngIf="isResetCompleted">
            <h2 class="text-center mb-4">Your password changed</h2>
            <p class="text-center" [innerHTML]="'auth.password_changed_message' | translate"></p>

            <div class="row justify-content-center">
                <div class="col-5">
                    <div class="form-row justify-content-center">
                        <div class="my-5 col-7">
                            <!-- <button class="cn-btn green full" type="button" routerLink="/">{{ 'auth.login' | translate }}</button> -->
                            <button class="cn-btn full black" type="button" routerLink="/">{{ 'auth.login' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Reset completed -->

    </div>
    <!-- /#forget-password-wrapper -->

</div>