import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LightboxModule } from 'ngx-lightbox';
import { ClickOutsideModule } from 'ng-click-outside';
import { CookieService } from 'ngx-cookie-service';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';

// Production
// import { enableProdMode } from '@angular/core';
// enableProdMode();

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
// import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { LoginSuccessComponent } from './auth/login-success/login-success.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LoginBypassComponent } from './auth/login-bypass/login-bypass.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// ADB2C
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { MsalProviderModule, MsalProvider } from '@chubb-auth/msal';
import { ChubbAuthMsal2PkceService, ChubbAuthMsal2PkceModule } from "@chubb-auth/msal2-pkce";

import { LoginComponent } from './login';
import { EligibilityComponent } from './eligibility/eligibility.component';
// ADB2C

import { ConfigService } from 'src/app/services/config.service';
// loadEnvConfig

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function loadConfiguration(configService: ConfigService): () => Promise<any> {
  return (): Promise<any> => configService.loadAuthConfig();
};

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    LoginSuccessComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    LoginBypassComponent,
    EligibilityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] } }),
    LightboxModule,
    ClickOutsideModule,
    SwiperModule,
    // ADB2C
    BrowserAnimationsModule, 
    MsalProviderModule, 
    ChubbAuthMsal2PkceModule,
    // ADB2C
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    CookieService,
    // ADB2C
    DatePipe, 
    MsalProvider, 
    ChubbAuthMsal2PkceModule,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // ADB2C
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: loadConfiguration, deps: [ConfigService], multi: true }
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }