import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Adb2cState } from '@chubb-auth/types';
import { AuthProviderFactory } from '@chubb-auth/types/lib/services/auth-provider-factory';
import { Observable } from 'rxjs/internal/Observable';
import { ChubbAuthenticationService } from '../_services/chubb-authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  authProvider: any;
  // adb2cState: Adb2cState;
  constructor(private router: Router, private readonly _authService: ChubbAuthenticationService) {
    //debugger;
    //private _router: Router,
    // private readonly _providerFactory: AuthProviderFactory,
    // private readonly _authService: AuthenticationService
  }


  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    //debugger;
    const authProvider = this._authService.getProvider();
    //debugger;
    console.log('STEP 1: authProvider initialized', authProvider);
    console.log('STEP 2: authProvider.isLoggedIn()', authProvider.isLoggedIn());
    if (/*sessionStorage.getItem('msal.interaction.status') &&*/ authProvider.isLoggedIn()) {
      ////debugger;
      return true;
    }
    console.log('STEP 3: before execute authProvider.login() , authProvider.isLoggedIn():', authProvider.isLoggedIn());
    authProvider.login();
    //debugger;
    return false;
    //return true;
    // .pipe(
    //   map((state: Adb2cState) => {
    //     console.log('Out:From auth.angular guard:-', state.accessToken);
    //     // state.accessToken;
    //     console.log('In:From auth.angular guard:-', state.accessToken);
    //     return true;
    //   })
    // );
    // return true;
  }

  //  ngOnInit() {
  //   const authProvider = this._authService.getProvider();
  //  }

  //   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

  //     // if (this.authProvider.isLoggedIn()) {
  //     //   return true;
  // //    }
  //     console.log('inside canActivate auth guard');
  //     this.authProvider.login();
  //     return false;



  //     // not logged in so redirect to login page with the return url
  //     // console.log('auth.guard.ts:: not logged in so redirect to login page with the return url');    
  //     // this.router.navigate(['/login']);
  //     // return false;
  //   }



}
