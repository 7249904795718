<div id="login-bypass-container" class="d-flex align-items-center">

    <div id="login-bypass-wrapper" class="container">
        <!-- logo -->
        <div class="cigna-logo lg mx-auto mb-4"></div>
        <!-- / logo -->

        <!-- Login form -->
        <div>

            <h2 class="text-center mb-4">By Pass ISAM</h2>
            <div class="row justify-content-center">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)" class="col-5 p-0" id="loginForm">

                    <div class="form-row justify-content-center">

                        <div class="form-group my-0" style="width: 333px;">
                            <div class="d-flex align-items-center  {{ loginForm.value.username ? 'is-focused' : '' }}" [ngClass]="displayFieldCss('username')">
                                <label class="icon" for="username">
                                    <i class="npicon-username"></i>
                                </label>
                                <div class="input-container">
                                    <input type="text" id="username" name="username" formControlName="username" autofocus>
                                    <label for="username">Username</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row justify-content-center">
                        
                        <div class="form-group my-0" style="width: 333px;">
                            <div class="d-flex align-items-center {{ loginForm.value.password ? 'is-focused' : '' }}" [ngClass]="displayFieldCss('password')">
                                <label class="icon" for="password">
                                    <i class="npicon-lock"></i>
                                </label>
                                <div class="input-container">
                                    <input type="password" id="password" name="password" formControlName="password">
                                    <label for="user">Password</label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="text-danger text-center" *ngIf="errorMsg">{{errorMsg}}</div>
                    <div class="form-row justify-content-center">
                        <div class="my-5 col-7">
                            <button class="cn-btn green full">Login</button>
                        </div>
                    </div>


                </form>
            </div>

        </div>
        <!-- / Login form -->

    </div>
</div>