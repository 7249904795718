import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthenService } from 'src/app/services/authen.service';
import { sidebar } from 'src/app/sidebar/sidebar'

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.sass']
})
export class ForgetPasswordComponent implements OnInit {

  sidebar: any;
  forgetPasswordForm: FormGroup;
  isButtonDisabled: boolean = false;
  isResetCompleted: boolean = false;
  isLangOpened: boolean = false;
  error: boolean = false;
  username: string = null;

  /**
   * Constructor
   * 
   * @param {FormBuilder} formBuilder
   * @param {Router} _router
   * @param {TranslateService} translate
   * @param {AuthenService} _authenService
   */
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    public translate: TranslateService,
    private _authenService: AuthenService
  ) {
    if (this._authenService.isLoggedIn()) {
      if (this._authenService.isAdmin()) {
        this._router.navigate(['/admin/hospital']);
      } else {
        this.sidebar = sidebar;
        this.sidebar = this.sidebar.filter(o => o.permits !== undefined).sort(o => o.permits);
        for (let permis of this.sidebar) {
          if (this._authenService.permission(permis.permits)) {
            var path = (permis.children !== undefined) ? permis.children[0].url : permis.url;
            this._router.navigate([path]);
            break;
          }
        }
      }
      return;
    }
    // Create form
    this.createForgetPasswordForm();
  }

  ngOnInit() {
  }

  createForgetPasswordForm(): void {
    this.forgetPasswordForm = this.formBuilder.group({
      user: [null, Validators.required]
    });
  }

  /**
   * is field valid
   * 
   * @param field
   */
  isFieldValid(field: string) {
    return !this.forgetPasswordForm.get(field).valid && this.forgetPasswordForm.get(field).touched;
  }

  // -----------------------
  // Public methods
  // -----------------------

  /**
   * display field
   */
  displayFieldCss(field: string) {
    if (this.forgetPasswordForm.value.user != this.username)
    {
      this.error = false;
    }

    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    }
  }

  /**
   * On Submit
   */
  onSubmit(e) {
    this.username = this.forgetPasswordForm.value.user;
    e.preventDefault();
    if (this.forgetPasswordForm.valid) {
      this.isButtonDisabled = true;
      const data = this.forgetPasswordForm.getRawValue();
      this._authenService.forgetPassword(data.user)
        .subscribe(
          result => {
            this.isResetCompleted = true;
            this.error = false;
          },
          error => {
            this.isButtonDisabled = false;
            this.error = true;
          }
        );
      // this.isResetCompleted = true;
      // this._router.navigate(['/']);
    }
  }

  changeLanguage(e, lang = null) {
    e.preventDefault();
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.openLangs();
  }

  openLangs() {
    this.isLangOpened = !this.isLangOpened;
  }

  closeLangs() {
    this.isLangOpened = false
  }

}
