import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthenService } from 'src/app/services/authen.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { sidebar } from 'src/app/sidebar/sidebar'

@Component({
  selector: 'app-login-bypass',
  templateUrl: './login-bypass.component.html',
  styleUrls: ['./login-bypass.component.sass']
})
export class LoginBypassComponent implements OnInit {

  sidebar: any;
  loginForm: FormGroup;
  errorMsg: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private authen: AuthenService,
    private router: Router
  ) { 
    sessionStorage.clear();
    localStorage.clear();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      'login-form-type': 'pwd'
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.loginForm.valid) {
      this.authen.loginByUser(this.loginForm.get('username').value)
        .subscribe(
          data => {
            if (data && data.token) {
              this.errorMsg = null;
              if (this.authen.isAdmin()) {
                this.router.navigate(['/admin/hospital']);
              } else {
                this.sidebar = sidebar;
                this.sidebar = this.sidebar.filter(o => o.permits !== undefined).sort(o => o.permits);
                for (let permis of this.sidebar) {
                  if (this.authen.permission(permis.permits)) {
                    var path = (permis.children !== undefined) ? permis.children[0].url : permis.url;
                    this.router.navigate([path]);
                    break;
                  }
                }
                
              }
            } else {
              this.errorMsg = 'User not found';
            }
          },
          error => {
            // console.log(error);
            this.errorMsg = 'User not found';
          }
        )
    }
  }

  isFieldValid(field: string) {
    return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    }
  }

}
