import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

// Services
import { AuthenService } from 'src/app/services/authen.service'

@Component({
  selector: 'app-logout',
  template: '<div></div>'
})

export class LogoutComponent implements OnInit {

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private authen: AuthenService
  ) {
    // Default
    this.authen.signout();
    //this.router.navigate(['/login']);
  }

  ngOnInit() {
    //this.router.navigate(['/login']);
  }
}