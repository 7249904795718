import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { LoginComponent } from './auth/login/login.component';
import { LoginSuccessComponent } from './auth/login-success/login-success.component';
// import { LogoutComponent } from './auth/logout/logout.component';
// import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
// import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LoginBypassComponent } from './auth/login-bypass/login-bypass.component';

// import { AuthGuard } from './guard/auth.guard';

import { LoginComponent } from './login';
import { AuthGuard } from './_guards';
import { EligibilityComponent } from './eligibility/eligibility.component';


const routes: Routes = [
  //no layout routes
  { path: 'login', component: LoginComponent, canActivate:[AuthGuard] },
  //{ path: 'eligibility1', component: EligibilityComponent, canActivate:[AuthGuard] },
  { path: 'eligibility', component: LoginSuccessComponent, canActivate:[AuthGuard] },
  //{ path: 'login', component: LoginComponent },

  // { path: 'login', component: LoginComponent },
  // { path: 'loginFail', component: LoginComponent },
  // { path: 'loginSuccess', component: LoginSuccessComponent },
  // { path: 'logout', component: LogoutComponent },
  // { path: 'forget-password', component: ForgetPasswordComponent },
  // { path: 'reset-password/:token', component: ResetPasswordComponent },
  // { path: 'login-bypass', component: LoginBypassComponent },
  
  { path: 'admin', loadChildren: () => import('./admin/hospital/hospital.module').then(m => m.HospitalModule), runGuardsAndResolvers: 'always' },
  { path: 'admin', loadChildren: () => import('./admin/reserve-pending/reserve-pending.module').then(m => m.Reserve_pendingAdminModule), runGuardsAndResolvers: 'always' },
  { path: 'admin', loadChildren: () => import('./admin/department/department.module').then(m => m.DepartmentModule), runGuardsAndResolvers: 'always' },
  { path: 'admin', loadChildren: () => import('./admin/guide/guide.module').then(m => m.GuideModule), runGuardsAndResolvers: 'always' },
  { path: 'admin', loadChildren: () => import('./admin/banner/banner.module').then(m => m.BannerModule), runGuardsAndResolvers: 'always' },
  { path: 'admin', loadChildren: () => import('./admin/setup/benefit/benefit.module').then(m => m.BenefitModule), runGuardsAndResolvers: 'always' },
  { path: 'admin', loadChildren: () => import('./admin/special/special.module').then(m => m.SpecialModule), runGuardsAndResolvers: 'always' },
  { path: '', loadChildren: () => import('./main/eligibility/eligibility.module').then(m => m.EligibilityModule) },
  { path: '', loadChildren: () => import('./main/payment/payment.module').then(m => m.PaymentModule) },
  { path: '', loadChildren: () => import('./main/reserve-pending/reserve-pending.module').then(m => m.Reserve_pendingModule) },
  { path: '', loadChildren: () => import('./main/status/status.module').then(m => m.StatusModule) },
  { path: '', loadChildren: () => import('./main/guide/guide.module').then(m => m.GuideModule) }, 
  { path: '', loadChildren: () => import('./main/policy-insurance/policy-insurance.module').then(m => m.PolicyInsuranceModule) }, 
  // otherwise redirect to home
  //{ path: '**', redirectTo: 'login' }
  { path: '**', redirectTo: 'login' }
  //{ path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';

// import { LoginComponent } from './login/login.component';
// import { LoginSuccessComponent } from './login-success/login-success.component';
// import { LogoutComponent } from './logout/logout.component';
// import { ForgetPasswordComponent } from './forget-password/forget-password.component';
// import { ResetPasswordComponent } from './reset-password/reset-password.component';

// // User
// import { EligibilityComponent } from './eligibility/eligibility.component';
// import { EligibilityDetailComponent } from './eligibility-detail/eligibility-detail.component';
// import { GuideComponent } from './guide/guide.component';
// import { GuideDetailComponent } from './guide-detail/guide-detail.component';

// // Admin
// import { HospitalComponent } from './admin/hospital/hospital.component';
// import { UserCreateComponent } from './admin/user-create/user-create.component';
// import { UserEditComponent } from './admin/user-edit/user-edit.component';
// import { UsersComponent } from './admin/users/users.component';
// import { DepartmentComponent } from './admin/department/department.component';
// import { DepartmentCreateComponent } from './admin/department-create/department-create.component';
// import { DepartmentEditComponent } from './admin/department-edit/department-edit.component';
// import { GuideComponent as AdminGuideComponent } from './admin/guide/guide.component';
// import { GuideCreateComponent } from './admin/guide-create/guide-create.component';
// import { GuideEditComponent } from './admin/guide-edit/guide-edit.component';
// import { BannerComponent } from './admin/banner/banner.component';
// import { BannerCreateComponent } from './admin/banner-create/banner-create.component';
// import { BannerEditComponent } from './admin/banner-edit/banner-edit.component';

// import { AuthGuard } from './guard/auth.guard'
// import { LoginBypassComponent } from './login-bypass/login-bypass.component';

// const routes: Routes = [
//   { path: 'login', component: LoginComponent },
//   { path: 'loginFail', component: LoginComponent },
//   { path: 'loginSuccess', component: LoginSuccessComponent },
//   { path: 'logout', component: LogoutComponent },
//   { path: 'forget-password', component: ForgetPasswordComponent },
//   { path: 'reset-password/:token', component: ResetPasswordComponent },
//   { path: 'login-bypass', component: LoginBypassComponent },

//   // User
//   { path: 'eligibility/:id', component: EligibilityDetailComponent, canActivate: [AuthGuard] },
//   { path: 'guide', component: GuideComponent, canActivate: [AuthGuard] },
//   { path: 'guide/:gid', component: GuideDetailComponent, canActivate: [AuthGuard] },
//   // Admin
//   { path: 'admin/hospital', component: HospitalComponent, canActivate: [AuthGuard] },
//   { path: 'admin/hospital/user/create/:hid', component: UserCreateComponent, canActivate: [AuthGuard] },
//   { path: 'admin/hospital/user/edit/:uid', component: UserEditComponent, canActivate: [AuthGuard] },
//   { path: 'admin/hospital/users/:hid', component: UsersComponent, canActivate: [AuthGuard] },
//   { path: 'admin/department', component: DepartmentComponent, canActivate: [AuthGuard] },
//   { path: 'admin/department/create', component: DepartmentCreateComponent, canActivate: [AuthGuard] },
//   { path: 'admin/department/edit/:did', component: DepartmentEditComponent, canActivate: [AuthGuard] },
//   { path: 'admin/guide', component: AdminGuideComponent, canActivate: [AuthGuard] },
//   { path: 'admin/guide/create', component: GuideCreateComponent, canActivate: [AuthGuard] },
//   { path: 'admin/guide/edit/:gid', component: GuideEditComponent, canActivate: [AuthGuard] },
//   { path: 'admin/banner', component: BannerComponent, canActivate: [AuthGuard] },
//   { path: 'admin/banner/create', component: BannerCreateComponent, canActivate: [AuthGuard] },
//   { path: 'admin/banner/edit/:id', component: BannerEditComponent, canActivate: [AuthGuard] },

//   // { path: '', component: EligibilityComponent, canActivate: [AuthGuard] },
//   { path: '', redirectTo: '/login', pathMatch: 'full' },
//   { path: '**', redirectTo: '/login', pathMatch: 'full' },
// ];

// // @NgModule({
// //   imports: [RouterModule.forRoot(routes)],
// //   exports: [RouterModule]
// // })
// // export class AppRoutingModule { }
// export const routing = RouterModule.forRoot(routes);
