import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

// Services
import { AuthenService } from "src/app/services/authen.service";
import { sidebar } from 'src/app/sidebar/sidebar'
import { ChubbAuthenticationService } from '../../_services/chubb-authentication.service';

@Component({
  selector: 'app-login-success',
  template: '<div></div>'
})
export class LoginSuccessComponent implements OnInit {
  sidebar: any;

  // user: string = '';
  //password: string = 'p@ssword';
  returnUrl: string;
  authProvider: any;

  constructor(
    private authen: AuthenService,
    private router: Router,
    private readonly _authService: ChubbAuthenticationService
  ) {
    console.log('Login Success AuthenService : ', this.authen.isLoggedIn())
    if (this.authen.isLoggedIn()) {
      console.log('isAdmin : ', this.authen.isAdmin());
      if (this.authen.isAdmin()) {
        console.log('admin : ', sidebar);
        console.log('path : ', '/admin/hospital');
        this.router.navigate(['/admin/hospital']);
      } else {
        console.log('non-admin : ', sidebar);
        this.sidebar = sidebar;
        this.sidebar = this.sidebar.filter(o => o.permits !== undefined).sort(o => o.permits);
        for (let permis of this.sidebar) {
          if (this.authen.permission(permis.permits)) {
            var path = (permis.children !== undefined) ? permis.children[0].url : permis.url;
            console.log('path : ', path);
            this.router.navigate([path]);
            break;
          }
        }
      }
      return;
    }
    // this.checkLogin.removeUserCookie();
    // localStorage.removeItem('login_limit');
    // localStorage.removeItem('userTemp');
    // this.user = this.cookieService.get('iv_user');
  }

  ngOnInit() {
    //debugger;
    if (!this.authen.isLoggedIn()) {
      //this.authen.login()

      //debugger;
      this.authProvider = this._authService.getProvider();
      var user = "";
      if (this.authProvider.account.idTokenClaims.username == null || this.authProvider.account.idTokenClaims.username == '' || this.authProvider.account.idTokenClaims.username == undefined) {
        user = this.authProvider.account.idTokenClaims.LanID;
        console.log('User LandID: ', user);
      } else {
        user = this.authProvider.account.idTokenClaims.username;
        console.log('User name: ', user);
      }
      //username = "DWINIYAKUL";

      this.authen.loginByUser(user)
        .pipe(first())
        .subscribe(
          data => {
            if (data) {
              localStorage.removeItem('submitted');
              window.location.reload();
              return;
            } else {
              this.authen.signout();
              this.router.navigate(['/loginFail']);
              return;
            }
          },
          error => {
            // console.log(error);
          }
        )
    }
    // if (this.authen.isLoggedIn()) {
    //  console.log('Username: ', username);  
    //   this.authen.loginByUser(username)
    //     .pipe(first())
    //     .subscribe(
    //       data => {
    //         if (data) {
    //           localStorage.removeItem('submitted');
    //           //window.location.reload();
    //           return;
    //         } else {
    //           this.authen.signout();
    //           this.router.navigate(['/loginFail']);
    //           return;
    //         }
    //       },
    //       error => {
    //         // console.log(error);
    //       }
    //     );
    // }
  }

}
