import { ResponseMode, StorageLocation } from "@chubb-auth/types";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'development',
  ssoDomain: 'auth.sit.chubb.com',
  scopeDomain: 'cbaadb2c08sit.onmicrosoft.com',
  clientid: 'fb7e00f8-191b-4a13-9abf-5e9660c6c5af',
  apiUrl: '/api/network-portal-api/',
  isamUrl: '/',
  // Package tiny
  //tinyMCEKey: '9c2fhgexg524pcro3bb47fny9w94kk0hn48di50rfzd1jfm1',
  tinyMCEKey: 'ylx7timwylgatfkd9ac3btxiu1hrswn3imh5cghwv53vesjr',
  adb2cUrl: 'https://nasit.chubbdigital.com/api/digital/adb2cplatform/',
  pUpdateURL: 'chp/updatePassword',
  createUser: 'chp/CreateUser',
  apimSubscriptionKeys: 'b84690b194f547789d69910bd250649b',
  UserRole: 'Non-Admin',                                   //Admin, Non-Admin                                          
  ResetPassPolicy: 'B2C_1A_INFINIXRESETPASSWORD',          //this policy is correct for adding a user
  HospitalID: '6acd6380-6bbf-4e05-b155-870d2fa0b531',      //HospitalId, need to check releavance of this field
  CreatedByApplication: 'Infinix Hospital Portal',         //static field
  RedirectURL: 'https://hospitalnetwork-uat.chubb.com/assets/auth/first_user_password.html', //once sign-up user link is clicked, 
  ResetPassEmailTemplate: 'https://hospitalnetwork-uat.chubb.com/assets/auth/Email.html', //HTML Template for new user signup
  FromAddress: 'do-not-reply@Chubb.com',
  EmailSubject: 'Chubb Password setting request',          //static
  Audience: 'e0b03b10-7325-4401-bf29-2dae963cc26d',        //static
  ClientId: 'fb7e00f8-191b-4a13-9abf-5e9660c6c5af',        //static
  ResetPassLinkValidForInDays: null,                      //static (need to check)
  AppKey: 'MVE8Q~p_dWjz1JrzRF6.ggVkcXMfONfvvte5zdqB'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.