import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eligibility',
  templateUrl: './eligibility.component.html',
  styleUrls: ['./eligibility.component.sass']
})
export class EligibilityComponent implements OnInit {

  constructor() { 
    ////debugger;
  }

  ngOnInit(): void {
    ////debugger;
  }

}
