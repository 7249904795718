<!-- <div class="reset-password-header">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <div class="col d-flex justify-content-end">
                <div class="language-switcher-container" (clickOutside)="closeLangs()">
                    <button class="language-switcher text-uppercase"
                        (click)="openLangs()">{{ translate.currentLang }}<span class="caret-down"><i
                                class="npicon-down"></i></span></button>
                    <ul class="language-lists" [ngClass]="{'opened': isLangOpened}">
                        <li *ngFor="let lang of translate.getLangs()">
                            <a href="#" (click)="changeLanguage($event, lang)">{{ lang }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div id="forget-password" class="d-flex align-items-center">

    <div id="forget-password-wrapper" class="container">
        <!-- logo -->
        <div class="cigna-logo lg mx-auto mb-4"></div>
        <!-- / logo -->

        <!-- Reset form -->
        <div *ngIf="!isResetCompleted">

            <h2 class="text-center mb-4">{{'auth.forget_password' | translate}}</h2>
            <p class="text-center" [innerHTML]="'auth.forget_password_description' | translate"></p>

            <div class="row justify-content-center">
                <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit($event)" class="col-5 p-0"
                    id="forgetPasswordForm">

                    <div class="form-row justify-content-center">

                        <div class="form-group my-0 col-8 {{ forgetPasswordForm.value.user ? 'is-focused' : '' }}">
                            <div class="d-flex align-items-center {{ forgetPasswordForm.value.user ? 'is-focused' : '' }}" [ngClass]="displayFieldCss('user')">
                                <!-- <label class="icon" for="user"><i class="npicon-username"></i></label> -->
                                <div class="input-container">
                                    <input type="text" id="user" name="user" formControlName="user" autofocus>
                                    <label for="user">{{ 'auth.username' | translate }}</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <p class="text-center mt-4 text-red" [innerHTML]="'auth.user_not_found' | translate" *ngIf="error"></p>

                    <div class="form-row justify-content-center">
                        <div class="my-5 col-7">
                            <!-- <button class="cn-btn green full" [ngClass]="{ 'disabled': isButtonDisabled }" [disabled]="isButtonDisabled">{{ 'auth.submit' | translate }}</button> -->
                            <button class="cn-btn full green" *ngIf="forgetPasswordForm.value.user && !isButtonDisabled && !error">{{ 'auth.submit' | translate }}</button>
                            <button class="cn-btn full gray" *ngIf="!forgetPasswordForm.value.user || isButtonDisabled || error" [disabled]="true">{{ 'auth.submit' | translate }}</button>
                        </div>
                    </div>

                    <div class="text-center">
                        <a routerLink="/">{{'auth.gohome' | translate}}</a>
                    </div>

                </form>
            </div>

        </div>
        <!-- / Reset form -->

        <!-- Reset completed -->
        <div *ngIf="isResetCompleted">
            <h2 class="text-center mb-4">Password Reset Email Sent</h2>
            <p class="text-center" [innerHTML]="'auth.reset_completed_message' | translate"></p>

            <!-- <div class="row justify-content-center">
                <div class="col-5">
                    <div class="form-row justify-content-center">
                        <div class="my-5 col-7">
                            <button class="cn-btn green full" type="button"
                                routerLink="/">{{ 'auth.gohome' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- /Reset completed -->

    </div>
    <!-- /#forget-password-wrapper -->

</div>