import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, fromEvent } from 'rxjs';

import { ChubbAuthenticationService } from './_services';
import { AuthenService } from 'src/app/services/authen.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'Chubb';
  public isIframe: boolean;
  errorMessage?: string;
  userActivity;
  userInactive: Subject<any> = new Subject();
  timeout: number = (60 * 60) * 1000;
  constructor(
    public translate: TranslateService,
    private router: Router,
    private authen: AuthenService,
    private route: ActivatedRoute,
    private authProvider: ChubbAuthenticationService
  ) {
    this.isIframe = window !== window.parent && !window.opener;
    console.log('AppComponent isIframe : ' + this.isIframe);
    translate.addLangs(['th', 'en']);
    translate.setDefaultLang('th');
    translate.use('th');
    if (this.authen.isLoggedIn()) {
    }
  }

  // setTimeout() {
  //   this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.timeout);
  // }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event: BeforeUnloadEvent) {
  //   event.preventDefault();
  //   debugger;
  //   if (this.authen.isLoggedIn()) {
  //     console.log('this.authen.isLoggedIn :',this.authen.isLoggedIn)
  //     this.authen.signout();
  //   }
  // }
  setTimeout(timeOut) {
    console.log('time out >> ' + timeOut + " Last move at >> " + Date());
    this.userActivity = setTimeout(() => {
      console.log("time out!!!");
      this.authen.signout();
      this.userInactive.next(undefined)
    }, timeOut);
  }

  @HostListener('window:mousemove') refreshUserState() {

    this.authProvider.getProvider().getAccessToken().subscribe(res => {
      console.log("===============######=============");
      console.log(res?.accessToken);
      let deToken = this.authen.getDecodedAccessToken(res?.accessToken);
      const formatTime = (ms) => {
        // Create a new JavaScript Date object based on the timestamp
        // multiplied by 1000 so that the argument is in milliseconds, not seconds
        var date = new Date(ms);

        // Hours part from the timestamp
        var hours = date.getHours();

        // Minutes part from the timestamp
        var minutes = date.getMinutes();

        // Seconds part from the timestamp
        var seconds = date.getSeconds();

        // // Will display time in 10:30:23 format
        // var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return [hours, minutes, seconds].map(v => String(v).padStart(2, '0')).join(':');
      }

      let date1 = new Date(`2000-01-01T${formatTime(deToken.iat * 1000)}Z`);
      let date2 = new Date(`2000-01-01T${formatTime(deToken.exp * 1000)}Z`);

      var diff = (date2.getTime() - date1.getTime()) / 1000;
      diff /= 60;
      diff = Math.abs(Math.round(diff));

      console.log(formatTime(deToken.iat * 1000));
      console.log(formatTime(deToken.exp * 1000));

      clearTimeout(this.userActivity);
      this.setTimeout((diff * 60) * 1000);
      this.authProvider.refreshToken();
    });

  }

  ngOnInit() {
    console.log('AppComponent ngOnInit ' + JSON.stringify(this.route.snapshot.params));

    //debugger;
    console.log('app.component.ts line 55');
    //this.router.navigate(['/eligibility1']);
    this.router.events.subscribe((evt) => {
      //debugger;
      console.log('app.component.ts line 59');
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    //this.subscribeToNativeNavigation();
  }

  ngOnDestroy() {
  }
}
