import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from "jwt-decode";
import { Router, ActivatedRoute } from '@angular/router';

import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { ChubbAuthenticationService } from '../_services/chubb-authentication.service';

//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/of';
@Injectable({
  providedIn: 'root'
})
// export class HttpClientHelper {

//   static adb2cUrl: string = environment.adb2cUrl;
// }
export class AuthenService {
  authProvider: any;
  url: string = environment.apiUrl;
  // adb2cUrl: string = environment.adb2cUrl;
  pUpdateURL: string = environment.pUpdateURL;
  // apimSubscriptionKeys: string = environment.apimSubscriptionKeys;
  isamUrl: string = environment.isamUrl;
  user: any = null;
  isByPassISAM: boolean = false;

  currentUserSubject: BehaviorSubject<User>;
  currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private _router: Router,
    private cookieService: CookieService,
    private readonly _authService: ChubbAuthenticationService

  ) {
    const token = localStorage.getItem('ApiUserToken');
    const user = token ? this.getDecodedAccessToken(token) : null;
    this.user = user ? user : null;
    this.currentUserSubject = new BehaviorSubject<User>(this.user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string = null, password: string = null) {
    return this.http.post<any>(`${this.url}Authorize/Login`, {}).pipe(map(user => {
      if (user && user.statusCode === 200) {
        localStorage.setItem('ApiUserToken', user.content.token);
        this.currentUserSubject.next(user.content);
        return user.content;
      } else {
        return false;
      }
    }));
  }

  loginByUser(nuser: string = null) {
    //return this.http.post<any>(`${this.url}Authorize/Login`, { user: username }).pipe(map(user => {
    return this.http.post<any>(`${this.url}Authorize/LoginByUser`, { user: nuser }).pipe(map(user => {
      if (user && user.statusCode === 200) {
        this.currentUserSubject.next(user.content);
        localStorage.setItem('ApiUserToken', user.content.token);
        localStorage.setItem('iv-user', user.content.userID);
        this.isByPassISAM = true;
        return user.content;
      } else {
        return false;
      }
    }));
  }

  signout() {
    const authProvider = this._authService.getProvider();
    this.apiLogout().subscribe((data: any) => {
      //logout
      localStorage.clear();
      sessionStorage.clear();
      this.cookieService.deleteAll();
      this.currentUserSubject.next(null);
      authProvider.logout();
    }, error => {
      if (error.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.deleteAll();
        this.currentUserSubject.next(null);
        authProvider.logout();
        return;
      } else {
        console.log(error);
      }
    });
  }
  apiLogout(): Observable<any> {
    const token = localStorage.getItem('ApiUserToken');
    var headers = new HttpHeaders().set('ApiUserToken', token);
    var options = { headers: headers };
    return this.http
      .post<any>(`${this.url}Authorize/logout`, null, options);
  }

  serviceSignout() {
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  isLoggedIn() {
    if (!this.currentUserSubject.value) {
      return false;
    }
    return true;
  }

  isAdmin() {
    if (this.isLoggedIn()) {
      if (this.currentUserValue.role == '1') {
        return true;
      }
      return false;
    }
    return false;
  }

  getID() {
    return this.currentUserValue.userID;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  checkLogin() {
    //debugger;
    // return this.http.post('/api/currentuser', { isByPassISAM: this.isByPassISAM });
    // return { isLoggedin: true, user: "DemoUser" };
    //return Observable.of(JSON.stringify('{isLoggedin: true, user: "DemoUser"}'));
    return new Observable((observer) => { return observer.next('{ isLoggedin: true, user: "DemoUser" }') })
  }

  forgetPassword(user: string) {
    const headers = new HttpHeaders()
      .set('api-version', '2');
    return this.http.post<any>(`${this.url}Authorize/Forget`, {
      user
    }, { headers })
  }
  resetPasswordADb2c(data: any, apimSubscriptionKeys: any, adb2cUrl: any) {
    const headers = new HttpHeaders()
      .set('Ocp-Apim-Subscription-Key', apimSubscriptionKeys);
    return this.http.put<any>(`${adb2cUrl + this.pUpdateURL}`, data, { headers })
  }
  // resetPasswordADb2c(data: any) {
  //     const headers = new HttpHeaders()
  //       .set('iv-user', localStorage.getItem('iv-user'));
  //   const body = { 
  //     username: data.username,
  //     password: data.password
  //    };
  //   return this.http.put<any>(`${this.url}user-edit/updatePassword`, body,{ headers })
  // }
  resetPassword(data: any) {
    const headers = new HttpHeaders()
      .set('api-version', '2');
    return this.http.post<any>(`${this.url}Authorize/ResetPassword`, data, { headers })
  }

  /**
   * Permission
   * @param permiss
   *
   * 1: ตรวจสอบสิทธิ์
   * 2: ตรวจสอบสถานะการเคลม
   * 3: จองสิทธิ์
   * 4: การวางบิล
   * 5: รายงาน
   */
  permission(permit: number) {
    var a = false;
    let permissions;
    var type = typeof (this.currentUserValue.permissions);
    if (type.toLowerCase() == "object") {
      permissions = this.currentUserValue.permissions
    } else {
      permissions = this.currentUserValue.permissions.split(',')
    }
    permissions.forEach(permis => {
      if (permis == permit.toString()) {
        a = true;
      }

    });
    return a;
    // var a =  permissions.indexOf(permit.toString());
    // return a  !== -1;
  }

}
