import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthenService } from 'src/app/services/authen.service';
import { sidebar } from 'src/app/sidebar/sidebar'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {

  sidebar: any;
  resetPasswordForm: FormGroup;
  isButtonDisabled: boolean = false;
  isResetCompleted: boolean = false;
  isLangOpened: boolean = false;
  pPattern: string = "[^-_\$& ]*";
  isSubmitted: boolean = false;
  token: string = '';
  user: any = null;

  /**
   * Constructor
   * 
   * @param {FormBuilder} formBuilder
   * @param {Router} _router
   * @param {ActivatedRoute} _route
   * @param {TranslateService} translate
   * @param {AuthenService} _authenService
   */
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    public translate: TranslateService,
    private _authenService: AuthenService
  ) {
    //
    if (this._authenService.isLoggedIn()) {
      if (this._authenService.isAdmin()) {
        this._router.navigate(['/admin/hospital']);
      } else {
        this.sidebar = sidebar;
        this.sidebar = this.sidebar.filter(o => o.permits !== undefined).sort(o => o.permits);
        for (let permis of this.sidebar) {
          if (this._authenService.permission(permis.permits)) {
            var path = (permis.children !== undefined) ? permis.children[0].url : permis.url;
            this._router.navigate([path]);
            break;
          }
        }
      }
      return;
    }
  }

  ngOnInit() {
    this.token = this._route.snapshot.paramMap.get('token');
    this.user = this.token ? this._authenService.getDecodedAccessToken(this.token) : null;
    // Create form
    this.createResetPasswordForm();
    if (!this.user) {
      this._router.navigate(['/login']);
      return;
    } else {
      // Check expiry date
      if (this.user.exp < new Date().getTime() / 1000) {
        this._router.navigate(['/login']);
        return;
      }
    }
  }

  createResetPasswordForm(): void {
    this.resetPasswordForm = this.formBuilder.group({
      username: [this.user.userID, Validators.required],
      usercode: [null, [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(10),
        Validators.pattern(this.pPattern)
      ]],
      pConfirm: [null, Validators.required]
    }, { validator: this.Confirming });
  }

  Confirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('usercode').value !== c.get('pConfirm').value) {
      return { invalid: true }
    }
  }

  /**
   * is field valid
   * 
   * @param field
   */
  isFieldValid(field: string) {
    return !this.resetPasswordForm.get(field).valid && this.resetPasswordForm.get(field).touched;
  }

  /**
   * is password length valid
   */
  isPasswordLengthValid() {
    return (this.resetPasswordForm.get('usercode').errors !== null && this.resetPasswordForm.get('usercode').touched) && (!this.resetPasswordForm.get('usercode').errors.minLength || !this.resetPasswordForm.get('usercode').errors.maxLength);
  }

  // -----------------------
  // Public methods
  // -----------------------

  /**
   * display field
   */
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    }
  }

  /**
   * On Submit
   */
  onSubmit(e) {
    e.preventDefault();
    this.isSubmitted = true;
    if (this.resetPasswordForm.valid) {
      this.isButtonDisabled = true;
      //const data = this.resetPasswordForm.getRawValue();
      const rawValue: any = Object.assign(this.resetPasswordForm.value);
      const data = {
        username: rawValue.username,
        password: rawValue.usercode,//btoa(rawValue.usercode),
        confirmPassword: rawValue.pConfirm//btoa(rawValue.pConfirm)
      };
      this._authenService.resetPassword(data)
        .subscribe(
          result => {
            this.isResetCompleted = true;
          },
          error => {
            this.isButtonDisabled = false;
          }
        );
      // this.isResetCompleted = true;
    }
  }

  changeLanguage(e, lang = null) {
    e.preventDefault();
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.openLangs();
  }

  openLangs() {
    this.isLangOpened = !this.isLangOpened;
  }

  closeLangs() {
    this.isLangOpened = false;
  }

}
