import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private url: string = '/config';
  private adb2cConfig: any = null;

  constructor(
    private http: HttpClient,
  ) {}

  async loadAuthConfig() {
    const res = await this.http.get<any>(`/config/authConfig`).toPromise();
    this.adb2cConfig = res.data;
  }

  getAuthConfig() {
    return this.adb2cConfig;
  }

  getAdb2cUrl(): Observable<any> {
    return this.http.get<any>(`${this.url}/adb2cUrl`);
  }

  getRedirectURL(): Observable<any> {
    return this.http.get<any>(`${this.url}/redirectURL`);
  }

  getEnv(): Observable<any> {
    return this.http.get<any>(`${this.url}/env`);
  }

}
