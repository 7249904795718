import { Injectable } from "@angular/core";
import { Adb2cConfig, Adb2cState, AuthProvider, AuthProviderFactory, ResponseMode } from "@chubb-auth/types";
import { environment } from "../../environments/environment";
import { StorageLocation } from "@chubb-auth/types";
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({ providedIn: "root" })
export class ChubbAuthenticationService {
  adb2cState?: Adb2cState;
  authProvider: any;
  counter: number = 0
  authConfig: any;


  constructor(
    private readonly _providerFactory: AuthProviderFactory,
    private router: Router,
    private _configService: ConfigService
  ) {

    const providerType = 'MSAL2WithEvents';
    this.authProvider = this._providerFactory.getProvider(providerType);
    //debugger;

    let config = this._configService.getAuthConfig();
    console.log('STEP 0: loadAuthConfig in ChubbAuthenticationService', config);
    config.stateLocation = StorageLocation.Session;
    config.responseMode = ResponseMode.Fragment;
    this.authConfig = config;
    console.log('STEP 4: this.authProvider', this.authProvider);
    this.authProvider.initialize(this.authConfig);
    //var isLoggedIn = new Promise((req, res) => { return this.authProvider.isLoggedIn(); })


    console.log('STEP 5: this.authProvider:', this.authProvider, ' this.authProvider.isLoggedIn(): ', this.authProvider.isLoggedIn());
    if (/*!sessionStorage.getItem('msal.interaction.status') &&*/ !this.authProvider.isLoggedIn()) {
      this.authProvider.login();
    }

    // acquire token.then() { login().subscribe( storeLoginInfo(); redirection happen here(); ) }
    this.authProvider.refreshState(0).subscribe((success: { isLoggedIn: any; }) => {
      //debugger;
      if (success && success.isLoggedIn) {
        console.log('inside refreshState subs success.isLoggedIn true', success);
        this.router.navigate(['/eligibility']);
      } else {
        console.log('inside refreshState subs success.isLoggedIn false, roles will not come in this case', success);
      }
    });
  }
  refreshToken() {
    this.authProvider.refreshState(0).subscribe((success: { isLoggedIn: any; }) => {
      //debugger;    
      if (!success.isLoggedIn) {
        console.log("not is loggin with refresh token");
        //console.log('inside refreshState subs success.isLoggedIn true', success);  
        //this.router.navigate(['/eligibility']);  
      }
    });
  }

  getProvider(): AuthProvider {
    if (this.authProvider) {
      return this.authProvider;
    }

    const providerType = 'MSAL2WithEvents';
    this.authProvider = this._providerFactory.getProvider(providerType);
    return this.authProvider;
  }
}
